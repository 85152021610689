import Image from 'next/image';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper';
import PropTypes from 'prop-types';

import 'swiper/css';
import 'swiper/css/free-mode';

const RecommendationCarousel = ({ recommendationBanners }) => {
  const data = recommendationBanners?.content || [];

  return (
    <div>
      <h6 className="text-[18px] md:text-xl font-semibold mb-3">{recommendationBanners?.title}</h6>
      <Swiper freeMode={true} slidesPerView="auto" spaceBetween={0} modules={[FreeMode]}>
        {data.map((item, index) => {
          return (
            <SwiperSlide key={index} style={{ width: 'auto' }} className="pr-2 md:pr-3 lg:pr-5">
              <div>
                <a
                  href={item?.redirectUrl ?? ''}
                  // style={{ width: 280, height: 180 }}
                  className="block relative w-[230px] md:w-[238px] lg:w-[210px] xl:w-[272px] 2xl:w-[286px] h-[120px] md:h-[140px] lg:h-[180px]"
                >
                  <img
                    src={item.image?.path || '#'}
                    alt={`recommendation-image-${index}`}
                    className="w-full h-full object-center object-cover"
                  />
                </a>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

RecommendationCarousel.propTypes = {
  recommendationBanners: PropTypes.any,
};

export default RecommendationCarousel;
