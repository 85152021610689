/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
/* eslint-disable prefer-template */
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { CONFIG_SITE } from 'constants/index';
import { getBannerList } from 'api/homepage-banner';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const HeroBanner = ({ data, style = {} }) => {
  const navigationPreviousRef = useRef(null);
  const navigationNextRef = useRef(null);

  const { data: bannerListQuery, isLoading: isLoadingBannerList } = useQuery(
    ['homepage-banner-list'],
    () => getBannerList(),
    {
      retry: false,
      enabled: !!CONFIG_SITE?.BANNER_MANAGEMENT,
    },
  );
  const bannerList = bannerListQuery?.data;

  return (
    <>
      {!isLoadingBannerList && (
        <Swiper
          style={{
            '--swiper-pagination-color': '#FFFFFF',
            '--swiper-pagination-bullet-inactive-color': '#FFFFFF',
            '--swiper-pagination-bullet-inactive-opacity': '0.44',
            '--swiper-pagination-bullet-size': '7px',
            '--swiper-pagination-bullet-horizontal-gap': '3px',
          }}
          modules={[Navigation, Pagination, A11y, Autoplay]}
          initialSlide={0}
          spaceBetween={2}
          pagination={{ clickable: true }}
          slidesPerView={1}
          className="relative rounded-lg overflow-hidden h-full rounded-lg"
          loop={bannerList?.length ? bannerList?.length > 1 : data?.length > 1}
          navigation={{
            prevEl: navigationPreviousRef.current,
            nextEl: navigationNextRef.current,
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPreviousRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
        >
          {(bannerList?.length ? bannerList : data).map((item, index) => (
            <SwiperSlide key={`hero-banner-${index}`}>
              <a
                href={item.redirectUrl ?? '/'}
                target="_blank"
                className={`w-full h-full main-banner-${index + 1} rounded-lg`}
                rel="noreferrer"
              >
                <img
                  src={item?.bannerImagePath ?? item?.image?.path}
                  alt={item?.bannerTitle ?? ''}
                  className="h-full w-full object-cover rounded-lg"
                />
              </a>
            </SwiperSlide>
          ))}
          <div
            ref={navigationPreviousRef}
            className="hidden lg:block absolute top-[50%] transform translate-y-[-50%] left-[12px] z-10 h-[40px] w-[40px] p-2 rounded-full bg-white opacity-70"
          >
            <ChevronLeftIcon width={24} height={24} color="gray" />
          </div>
          <div
            ref={navigationNextRef}
            className="hidden lg:block absolute top-[50%] transform translate-y-[-50%] right-[12px] z-10 h-[40px] w-[40px] p-2 rounded-full bg-white opacity-70"
          >
            <ChevronRightIcon width={24} height={24} color="gray" />
          </div>
        </Swiper>
      )}
    </>
  );
};

export default HeroBanner;
