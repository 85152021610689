/* eslint-disable import/no-unresolved */
/* eslint-disable no-param-reassign */
import React, { useMemo, useRef, useEffect, useState, useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y } from 'swiper';
import { ChevronLeftIcon, ChevronRightIcon, LocationMarkerIcon } from '@heroicons/react/solid';
import { uniqueId, upperFirst } from 'lodash';
import { parseCookies } from 'nookies';
import clsx from 'clsx';
import Link from 'next/link';
import { CONFIG_SITE } from 'constants/index';
import { useAuth } from 'utils/context/auth';
import { currencyFormatter } from 'global/utils/currency.utils';
import { isValidBuyingType } from 'global/utils/global-config.utils';
import { BUYING_OPTIONS } from 'global/constants/buying-options';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const HighlightProductCarousel = ({ productSection }) => {
  const banners = productSection?.content?.banner ? [productSection?.content?.banner] : [];
  const data = productSection?.content?.products || [];
  const title = productSection?.title || '';
  const seeMoreUrl = productSection?.categoryCode
    ? `/c/${productSection?.categoryCode}`
    : `/search?cateIds=${productSection?.categoryId || ''}`;

  const navigationPreviousRef = useRef(null);
  const navigationNextRef = useRef(null);

  const cookies = parseCookies();
  const { user } = useAuth();
  const { isLogedIn } = user.auth;
  const [isLogin, setIsLogin] = useState(false);
  const [isBuyerStaff, setIsBuyerStaff] = useState(false);

  const isBuyingTypeRent = isValidBuyingType(CONFIG_SITE, BUYING_OPTIONS.rent);

  const addressProduct = (product) => {
    const { productTypePropertyCity, productTypePropertyDistrict, sellerStore } = product || {};
    const { cityName: storeCityName, districtName: storeDistrictName } =
      sellerStore?.storeAddress || {};

    const propertyDistrictName = productTypePropertyDistrict || '';
    const propertyCityName = productTypePropertyCity || '';

    const districtName = isBuyingTypeRent ? propertyDistrictName : storeDistrictName;
    const cityName = isBuyingTypeRent ? propertyCityName : storeCityName;

    const labels = [
      upperFirst(String(districtName).toLowerCase()),
      upperFirst(String(cityName).toLowerCase()),
    ];
    return labels.filter((item) => !!item).join(', ');
  };

  const loadPriceValidate = (product) => {
    if (CONFIG_SITE.HIDE_PRODUCT_PRICE_IF_UNAUTHENTICATED) {
      if (isLogin) {
        return `Rp${currencyFormatter(Math.ceil(product?.finalPrice))}`;
      }
      return 'Masuk untuk Lihat Harga';
    }
    return `Rp${currencyFormatter(Math.ceil(product?.finalPrice))}`;
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    cookies.isLogedIn &&
      setIsLogin(true) &&
      // eslint-disable-next-line no-undef, eqeqeq
      setIsBuyerStaff(user.auth.role == USER_ROLE.BUYER_STAFF);
  }, [isLogedIn]);

  const handleSelect = useCallback((product) => (event) => {}, []);

  const slider = useMemo(
    () => (
      <Swiper
        modules={[Navigation, A11y]}
        slidesPerView="auto"
        className="relative rounded-lg h-[364px] overflow-x-visible"
        navigation={{
          prevEl: navigationPreviousRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper) => {
          setTimeout(() => {
            swiper.params.navigation.prevEl = navigationPreviousRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;

            // Re-init navigation
            swiper.navigation.destroy();
            swiper.navigation.init();
            swiper.navigation.update();
          }, 200);
        }}
        onSwiper={(swiper) => {
          setTimeout(() => {
            swiper.params.navigation.prevEl = navigationPreviousRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;

            // Re-init navigation
            swiper.navigation.destroy();
            swiper.navigation.init();
            swiper.navigation.update();
          }, 200);
        }}
      >
        <SwiperSlide style={{ width: 120 }} className="hidden md:block" />
        {data.map((product, index) => (
          <SwiperSlide
            key={uniqueId()}
            style={{ width: 'auto', paddingRight: '1.2rem' }}
            className="pt-[20px]"
          >
            <Link href={`/product/${product?.productId}`} key={index}>
              <a
                onClickCapture={handleSelect(product)}
                className={`flex h-[304px] w-[200px] rounded-2.5xl overflow-hidden shadow-lg z-10 bg-white ${
                  product?.isProductLimitation === false ? 'cursor-not-allowed' : 'cursor-pointer'
                }`}
                onClick={(e) => {
                  if (product?.isProductLimitation === false) e.preventDefault();
                }}
                role="button"
                tabIndex={0}
              >
                <figure className="flex flex-col justify-between w-full relative">
                  {product?.isProductLimitation === false && (
                    <img
                      className="absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] w-[150px] h-[150px]"
                      src="/assets/illustrations/contact-admin.png"
                      alt="contact-admin"
                    />
                  )}
                  <div
                    className={`flex h-[160px] w-full ${
                      product?.isProductLimitation === false ? 'opacity-50' : ''
                    }`}
                  >
                    <img
                      src={product?.image?.path}
                      alt={product?.image?.seo?.alt}
                      className="object-contain w-full h-full"
                    />
                  </div>
                  <div
                    className={`flex-1 ${
                      product?.isProductLimitation === false ? 'opacity-50' : ''
                    }`}
                  >
                    <figcaption className="p-4 w-full">
                      <p className="mb-2 font-semibold text-grayScale05 min-h-[40px] line-clamp-2 ">
                        {product?.title}
                      </p>
                      <p
                        className={`mb-2 font-semibold text-theme-primary-main line-clamp-1 ${
                          CONFIG_SITE.HIDE_PRODUCT_PRICE_IF_UNAUTHENTICATED && !isLogin
                            ? 'text-xs'
                            : 'text-sm'
                        }`}
                      >
                        {loadPriceValidate(product)}
                      </p>
                      <div className="flex flex-row items-center space-x-2 h-[32px]">
                        <LocationMarkerIcon width={15} height={15} className="text-greenSuccess" />
                        <p className="text-gray-600 break-words text-ellipsis w-full line-clamp-2 text-xs">
                          {addressProduct(product)}
                        </p>
                      </div>
                    </figcaption>
                  </div>
                </figure>
              </a>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    ),
    [data],
  );

  return (
    <div>
      <div className="flex mt-3 mb-[20px] justify-between items-center">
        <h6 className="text-[18px] md:text-xl font-semibold">{title}</h6>
        <Link href={`${seeMoreUrl || '/'}`}>
          <a className="text-base text-theme-primary-main hover:underline">Lihat Lainnya &gt;</a>
        </Link>
      </div>
      <div className="pl-0 relative">
        <div className="rounded-2.5xl overflow-hidden max-w-[233px] absolute w-[240px] left-0 hidden md:block">
          <div className="absolute top-0 left-0 h-full w-full bg-black opacity-20" />
          {banners.map((banner) => (
            <div key={uniqueId()} className="h-[364px] w-[240px]">
              <img
                src={`${banner?.image?.path}`}
                alt={`${banner?.image?.seo?.alt}`}
                className="bg-gradient-to-r from-cyan-500 to-blueInfo h-full object-cover object-center w-full"
              />
            </div>
          ))}
        </div>
        {slider}
        <>
          <div
            ref={navigationPreviousRef}
            className={clsx([
              data?.length >= 5 ? 'hidden md:block' : 'hidden',
              'absolute top-[40%] left-[-20px] lg:left-[90px] z-10 h-[40px] w-[40px] p-2 rounded-full bg-red-gradient',
            ])}
          >
            <ChevronLeftIcon width={24} height={24} color="white" />
          </div>
          <div
            ref={navigationNextRef}
            className={clsx([
              data?.length >= 5 ? 'hidden md:block' : 'hidden',
              'absolute top-[40%] -right-[18px] z-10 h-[40px] w-[40px] p-2 rounded-full bg-red-gradient hidden md:block',
            ])}
          >
            <ChevronRightIcon width={24} height={24} color="white" />
          </div>
        </>
      </div>
    </div>
  );
};

HighlightProductCarousel.propTypes = {};

export default HighlightProductCarousel;
