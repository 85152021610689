import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { clearEmptyObject } from 'global/utils/object.utils';
import { SearchIcon } from '@heroicons/react/solid';

const Search = () => {
  const router = useRouter();
  const [searchValue, setSearchValue] = useState('');

  const onSearchProduct = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const onSubmitSearchProduct = () => {
    router.push({
      pathname: '/search',
      query: clearEmptyObject({
        q: searchValue,
      }),
    });
    // router.push(`/search?q=${searchValue || ''}`);
  };

  useEffect(() => {
    if (router.query) {
      setSearchValue(router.query.q);
    }
  }, [router.query]);
  return (
    <div className="w-full md:w-[610px] lg:w-[710px] bg-theme-primary-main rounded-lg md:absolute md:bottom-[95px] md:left-[50%] md:transform md:translate-x-[-50%] xl:left-[68px] xl:translate-x-0 p-5 sm:p-7 md:p-0">
      <p className="block md:hidden text-[18px] leading-[24px] tracking-[.2px] text-white text-left mb-[15px] font-medium">
        Sewa Properti dan Ruang Kantor Langsung dari Ahlinya!
      </p>

      <div className="flex flex-1 h-[30px] md:h-[60px] relative">
        <div className="relative flex items-stretch flex-grow focus-within:z-10">
          <input
            type="text"
            autoComplete='off'
            name="search"
            id="search"
            className="bg-[#F2F2F2] block w-full border border-transparent rounded-[3px] md:rounded-lg pl-[16px] !text-[10px] md:!text-lg"
            placeholder="Ketik Kategori, Lokasi ataupun Nama Properti"
            value={searchValue}
            onChange={onSearchProduct}
            onKeyDown={(event) => {
              if (event.type === 'keydown' && event.key === 'Enter') {
                event.preventDefault();
                router.push({
                  pathname: '/search',
                  query: clearEmptyObject({
                    q: searchValue,
                  }),
                });
              }
            }}
          />
        </div>
        <button
          type="button"
          className="flex gap-[8px] items-center justify-center absolute z-10 right-1 top-[50%] translate-y-[-50%] h-[25px] md:h-[50px] p-1.5 md:px-7 rounded-[3px] md:rounded-lg text-sm md:text-[20px] leading-[30px] tracking-[.2px]  font-medium bg-[#00A925] focus:outline-none text-white "
          onKeyDown={onSubmitSearchProduct}
          onClick={onSubmitSearchProduct}
        >
          <SearchIcon className="h-3.5 w-3.5 md:h-5 md:w-5" />
          <span className="hidden md:block">Cari</span>
        </button>
      </div>
    </div>
  );
};

export default Search;
