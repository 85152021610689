/* eslint-disable import/prefer-default-export */
import request, { apiRequest, API_PREFIX } from 'utils/request.utils';

export const getBannerList = async () => {
  try {
    const response = await apiRequest({ prefix: API_PREFIX.v2 })({ withAuth: false }).get(
      'contentful/banner/list',
    );
    return response.data;
  } catch (e) {
    throw e.message;
  }
};
