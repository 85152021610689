import React from 'react';
import Image from 'next/image';
import Popup from 'components/Popup';
import { CONFIG_SITE } from 'constants/index';

const RegisterPopup = ({ isShow, close }) => {
  const buyerRegistrationForm =
    'Nama Perusahaan: \n' +
    'Nama Lengkap (PIC): \n' +
    'E-mail: \n' +
    'NIK: \n' +
    'Posisi/Jabatan: \n' +
    'No Telpon kantor: \n' +
    'No Handphone: \n' +
    'Alamat: ';
  const sendBuyerRegistrationEmail = () => {
    var link =
      'mailto:me@example.com' +
      '&subject=' +
      encodeURIComponent(
        `${CONFIG_SITE?.CONTENT?.COMPANY?.DOMAIN} - Register Buyer Parent Company`
      ) +
      '&body=' +
      encodeURIComponent(buyerRegistrationForm);
    window.location.href = link;
  };

  return (
    <Popup isShow={isShow} close={close}>
      <div className="flex flex-col max-w-[580px] text-left text-grayScale04 mx-3 text-sm md:text-base">
        <div className="relative mb-2 flex items-center text-grayScale05 space-x-2">
          <h2 className="font-semibold lg:text-2xl">Daftar ke akun</h2>
          <div className="w-[80px] h-auto lg:w-[100px] lg:h-[30px] relative mr-7 top-1">
            <Image
              src={CONFIG_SITE.LOGO_URL}
              alt="Logo"
              width="109px"
              height="30px"
              objectFit="cover"
            />
          </div>
        </div>
        <p className="text-grayScale04 font-normal ">
          Ayo daftarkan dirimu di platform {CONFIG_SITE?.CONTENT?.COMPANY?.BRAND} (Seller & Buyer),
          dan nikmati beragam keuntungannya:
        </p>
        <ol className="font-semibold  list-decimal ml-4 my-8">
          <li>Order Barang lebih cepat</li>
          <li>Request dan Approval pembelian barang lebih mudah.</li>
          <li>Semua transaksi transparan, tercatan dalam sistem</li>
        </ol>
        <p className="mb-5 text-grayScale04 font-normal ">
          Segera daftarkan dirimu dengan menekan tombol di bawah ini 👇🏻
        </p>
        <button
          className="relative flex flex-row justify-center items-center bg-red-gradient text-white  py-3 rounded-full"
          onClick={sendBuyerRegistrationEmail}
        >
          <div className="mr-3 w-[25px] h-[25px] relative top-1">
            <Image
              src="/assets/icons/icon-email.svg"
              alt="email Logo"
              width="25px"
              height="25px"
              objectFit="cover"
            />
          </div>
          Daftar melalui Email
        </button>
      </div>
    </Popup>
  );
};

export default RegisterPopup;
