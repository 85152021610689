import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y } from 'swiper';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const HeroBanner = ({ data, style = {} }) => {
  const navigationPreviousRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <Swiper
      modules={[Navigation, Pagination, A11y]}
      initialSlide={1}
      spaceBetween={0}
      pagination={{ clickable: true }}
      slidesPerView={1}
      className="relative overflow-hidden h-[150px] sm:h-[180px] md:h-[280px] lg:h-[430px] xl:h-[540px] max-w-screen-2xl mx-auto"
      loop
      navigation={{
        prevEl: navigationPreviousRef.current,
        nextEl: navigationNextRef.current,
      }}
      onBeforeInit={(swiper) => {
        swiper.params.navigation.prevEl = navigationPreviousRef.current;
        swiper.params.navigation.nextEl = navigationNextRef.current;
      }}
    >
      {data.map((item, index) => {
        return (
          <SwiperSlide key={`hero-banner-${index}`}>
            <a href={item.redirectUrl ?? '/'} className="w-full h-full">
              <img
                src={item.image.path}
                alt=""
                className="h-full w-full object-left object-cover"
              />
            </a>
          </SwiperSlide>
        );
      })}
      <div
        ref={navigationPreviousRef}
        className="absolute top-[50%] left-[12px] z-10 h-[40px] w-[40px] p-2 rounded-full bg-white opacity-70"
      >
        <ChevronLeftIcon width={24} height={24} color="gray" />
      </div>
      <div
        ref={navigationNextRef}
        className="absolute top-[50%] right-[12px] z-10 h-[40px] w-[40px] p-2 rounded-full bg-white opacity-70"
      >
        <ChevronRightIcon width={24} height={24} color="gray" />
      </div>
    </Swiper>
  );
};

export default HeroBanner;
