import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';

const HeroCategoryMobile = ({ categories }) => {
  // Temporary hide "Consumer Goods & Disposable", "Medical Equipment & Health", and "Voucher"
  const categoriesModified = categories?.filter(
    (category) =>
      category?.name !== 'Consumer Goods & Disposable' &&
      category?.name !== 'Medical Equipment & Health' &&
      category?.name !== 'Voucher',
  );
  return (
    <Swiper freeMode slidesPerView="auto" spaceBetween={0} modules={[FreeMode]}>
      {categoriesModified?.map((category, index) => (
        <SwiperSlide key={index} style={{ width: 'auto', paddingRight: '1.2rem' }}>
          <a
            href={category.redirectUrl ? category.redirectUrl : '/'}
            className="flex flex-col gap-1 font-normal text-grayScale05 hover:text-red-500  text-[12px] cursor-pointer w-[70px] h-auto items-center"
          >
            <div className="w-[48px] h-[48px] relative bg-white rounded-[8px] justify-center items-center shadow flex m-0">
              <img
                src={category.imgUrl}
                alt={category.name}
                width="36px"
                height="36px"
                layout="fixed"
              />
            </div>
            <p className="text-center text-ellipsis w-full text-xs">{category.name}</p>
          </a>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default HeroCategoryMobile;
