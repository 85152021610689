import React from 'react';
import Link from 'next/link';
import SimpleBar from 'simplebar-react';

import 'simplebar/dist/simplebar.min.css';

const HeroCategory = ({ categories }) => {
  // Temporary hide "Consumer Goods & Disposable", "Medical Equipment & Health", and "Voucher"
  const categoriesModified = categories?.filter(
    (category) =>
      category?.name !== 'Consumer Goods & Disposable' &&
      category?.name !== 'Medical Equipment & Health' &&
      category?.name !== 'Voucher',
  );

  return (
    <div
      className="rounded-[15px] h-full px-1 py-0"
      style={{
        background: 'rgba(255, 255, 255, 0.7)',
      }}
    >
      <SimpleBar style={{ height: '100%' }}>
        <ul className="space-y-3 p-5">
          {categoriesModified?.map((category, index) => (
            <li key={index} className="flex flex-row items-center space-x-4">
              <div className="flex w-8 h-8 max-w-8 relative bg-white rounded-[8px] justify-center items-center shadow">
                <img
                  src={category.imgUrl}
                  alt={category.name}
                  width="23x"
                  height="23px"
                  layout="fixed"
                />
              </div>
              <Link href={category.redirectUrl ? category.redirectUrl : '/'}>
                <a
                  // href={category.redirectUrl ? category.redirectUrl : '/'}
                  className="font-normal text-grayScale05 hover:text-red-500  text-[12px] cursor-pointer text-xs md:text-base"
                >
                  {category.name}
                </a>
              </Link>
            </li>
          ))}
        </ul>
      </SimpleBar>
    </div>
  );
};

export default HeroCategory;
