/* eslint-disable arrow-body-style */
import { CONFIG_SITE } from 'constants/index';
import HeroBanner from './_components/HeroBanner';
import MainLayout from 'layouts/MainLayout';
import { NextSeo } from 'next-seo';
import mockHomepageData from '../../../public/mock-data/homepage';
import { useQuery } from 'react-query';
import { getHomepageData } from 'api/homepage';
import { useMemo } from 'react';
import { cloneDeep } from 'lodash';
import RecommendationCarousel from './_components/RecommendationCarousel';
import HighlightedProducts from './_components/HighlightedProducts';

const HomepageIDFood = () => {
  const { hero_banner, recommendation_banner, middle_banner, highlighted_products } =
    mockHomepageData;

  const homepageHighlightedProductsQuery = useQuery(['homepage-highlighted-products'], () =>
    getHomepageData(),
  );

  const highlightedProducts = useMemo(() => {
    const data = cloneDeep(highlighted_products);

    const { data: homepageHighlightedProducts } = homepageHighlightedProductsQuery || {};

    const firstHighlight =
      homepageHighlightedProducts?.data?.length >= 16
        ? [...(homepageHighlightedProducts?.data || [])].splice(0, 16)
        : homepageHighlightedProducts?.data || [];

    if (data.length >= 0) {
      data[0].content.products = [...firstHighlight].map((item) => {
        return {
          ...item,
          image: {
            path: item.imagePath,
          },
          sellerStore: {
            storeAddress: {
              cityName: item.storeCity,
              districtName: item.storeDistrict,
            },
          },
        };
      });
    }

    return data;
  }, [homepageHighlightedProductsQuery.data, highlighted_products]);

  return (
    <MainLayout isHomepage>
      {CONFIG_SITE?.SEO?.CUSTOM_SEO_HOMEPAGE === true ? (
        <NextSeo
          title={CONFIG_SITE?.SEO?.MAINSITE?.TITLE}
          description={CONFIG_SITE?.SEO?.MAINSITE?.DESC}
          siteName={CONFIG_SITE?.CONTENT?.COMPANY?.BRAND}
        />
      ) : (
        <NextSeo title={CONFIG_SITE?.CONTENT?.COMPANY?.BRAND} />
      )}
      <div className="">
        <HeroBanner data={hero_banner.content} />
      </div>
      <section className="section-container">
        <RecommendationCarousel recommendationBanners={recommendation_banner} />
      </section>
      <section className="section-container">
        <HighlightedProducts products={highlightedProducts[0]?.content?.products || []} />
      </section>
    </MainLayout>
  );
};

export default HomepageIDFood;
