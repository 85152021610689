/* eslint-disable arrow-body-style */
import React, { useEffect, useMemo, useState } from 'react';
import { NextSeo } from 'next-seo';
import useCategoryStore from 'stores/category.store';

import MainLayout from 'layouts/MainLayout';
import { Row, Col } from 'antd';
import HeroBanner from './_components/HeroBanner';
import RecommendationCarousel from './_components/RecommendationCarousel';
import HighlightProductCarousel from './_components/HighlightProductCarousel';
import { useQuery } from 'react-query';
import { getHomepageData } from 'api/homepage';
import mockHomepageData from '../../../public/mock-data/homepage';
import { cloneDeep } from 'lodash';
import { CONFIG_SITE } from 'constants/index';

const HomePage = () => {
  const { hero_banner, recommendation_banner, highlighted_products } = mockHomepageData;

  const { categories } = useCategoryStore();

  const homepageHighlightedProductsQuery = useQuery(['homepage-highlighted-products'], () =>
    getHomepageData(),
  );

  const highlightedProducts = useMemo(() => {
    const data = cloneDeep(highlighted_products);

    const { data: homepageHighlightedProducts } = homepageHighlightedProductsQuery || {};

    const officeRoomProducts = [...(homepageHighlightedProducts?.data || [])]
      .filter((item) => item?.categoryName === 'Ruang Kantor')
      .splice(0, 4);
    const otherProducts = [...(homepageHighlightedProducts?.data || [])]
      .filter((item) => item?.categoryName === 'Properti Lainnya')
      .splice(0, 4);

    if (data.length >= 0) {
      data[0].content.products = [...officeRoomProducts].map((item) => {
        return {
          ...item,
          image: {
            path: item.imagePath,
          },
          sellerStore: {
            storeAddress: {
              cityName: item.storeCity,
              districtName: item.storeDistrict,
            },
          },
        };
      });
    }

    if (data.length > 1) {
      data[1].content.products = [...otherProducts].map((item) => {
        return {
          ...item,
          image: {
            path: item.imagePath,
          },
          sellerStore: {
            storeAddress: {
              cityName: item.storeCity,
              districtName: item.storeDistrict,
            },
          },
        };
      });
    }

    return data;
  }, [homepageHighlightedProductsQuery.data, highlighted_products]);

  return (
    <MainLayout isHomepage>
      <NextSeo title={CONFIG_SITE?.CONTENT?.COMPANY?.BRAND} />
      <section className="relative">
        <div className="absolute inset-0 bg-[#DADCE0] opacity-[0.25]" />
        <section className="section-container-hero-callpro">
          <Row>
            {/* <Col xs={24} lg={8} xl={6} className="hidden lg:block">
              <HeroCategory categories={categories} />
            </Col> */}
            <Col xs={24} lg={24} xl={24} flex="auto">
              <HeroBanner data={hero_banner.content} />
            </Col>
            {/* <Col xs={24} className="block lg:hidden pt-4">
              <HeroCategoryMobile categories={categories} />
            </Col> */}
          </Row>
        </section>
        <div className="container mx-auto" />
      </section>

      <section className="section-container">
        <RecommendationCarousel recommendationBanners={recommendation_banner} />
      </section>
      {highlightedProducts[0] && (
        <section className="section-container">
          <HighlightProductCarousel productSection={highlightedProducts[0]} />
        </section>
      )}

      {highlightedProducts[1] && (
        <section className="section-container">
          <HighlightProductCarousel productSection={highlightedProducts[1]} />
        </section>
      )}
    </MainLayout>
  );
};

export default HomePage;
