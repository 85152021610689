import { LocationMarkerIcon } from '@heroicons/react/solid';
import { CONFIG_SITE, USER_ROLE } from 'constants/index';
import { currencyFormatter } from 'global/utils/currency.utils';
import { startCase, toLower } from 'lodash';
import Link from 'next/link';
import { parseCookies } from 'nookies';
import { useEffect, useState } from 'react';
import { useAuth } from 'utils/context/auth';

const HighlightedProducts = ({ products = [] }) => {
  const cookies = parseCookies();
  const { user } = useAuth();
  const { isLogedIn } = user.auth;

  const [isLogin, setIsLogin] = useState(false);
  const [isBuyerStaff, setIsBuyerStaff] = useState(false);

  const loadPriceValidate = (product) => {
    if (CONFIG_SITE?.HIDE_PRODUCT_PRICE_IF_UNAUTHENTICATED) {
      if (isLogin) {
        return 'Rp' + currencyFormatter(Math.ceil(product?.finalPrice));
      } else {
        return 'Masuk untuk Lihat Harga';
      }
    } else {
      return 'Rp' + currencyFormatter(Math.ceil(product?.finalPrice));
    }
  };
  const addressProduct = (product) => {
    const { productTypePropertyCity, productTypePropertyDistrict, sellerStore } = product || {};
    const { cityName: storeCityName, districtName: storeDistrictName } =
      sellerStore?.storeAddress || {};

    const districtName = storeDistrictName;
    const cityName = storeCityName;

    const labels = [
      startCase(toLower(String(districtName))),
      startCase(toLower(String(cityName))),
    ];
    return labels.filter((item) => !!item).join(', ');
  };

  useEffect(() => {
    cookies.isLogedIn &&
      setIsLogin(true) &&
      setIsBuyerStaff(user.auth.role == USER_ROLE.BUYER_STAFF);
  }, [isLogedIn]);

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-3 lg:gap-5">
      {products.map((product, index) => {
        return (
          <Link href={`/product/${product?.productId}`} key={index}>
            <a className="flex h-[304px] rounded-2.5xl overflow-hidden shadow-lg z-10 bg-white cursor-pointer">
              <figure className="flex flex-col justify-between w-full">
                <div className="flex h-[160px] w-full">
                  <img
                    // width="100%"
                    // height={160}
                    src={product?.image?.path}
                    alt={product?.image?.seo?.alt}
                    className="object-contain w-full h-full"
                  // className="object-center object-cover w-full h-full"
                  />
                </div>
                <div className="flex-1">
                  <figcaption className="p-4 w-full">
                    <p className="mb-2 font-semibold text-grayScale05 min-h-[40px] line-clamp-2 ">
                      {product?.title}
                    </p>
                    <p
                      className={`mb-2 font-semibold text-theme-primary-main line-clamp-1 ${CONFIG_SITE?.HIDE_PRODUCT_PRICE_IF_UNAUTHENTICATED && !isLogin
                          ? 'text-xs'
                          : 'text-sm'
                        }`}
                    >
                      {loadPriceValidate(product)}
                    </p>
                    <div className="flex flex-row items-center space-x-2 h-[32px]">
                      <LocationMarkerIcon width={15} height={15} className="text-greenSuccess" />
                      <p className="text-gray-600 break-words text-ellipsis w-full line-clamp-2 text-xs">
                        {addressProduct(product)}
                      </p>
                    </div>
                  </figcaption>
                </div>
              </figure>
            </a>
          </Link>
        );
      })}
    </div>
  );
};

export default HighlightedProducts;
