import React, { useEffect } from 'react';
import Homepage from 'containers/Homepage';
import HomepageCallpro from 'containers/HomepageCallpro';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { CONFIG_SITE } from 'constants/index';
import { WHITELABEL_KEYS } from 'global/constants/whitelabel-keys';
import HomepageIDFood from 'containers/HomepageIDFood';

const HomePage = (props) => {
  const renderContent = () => {
    if (CONFIG_SITE?.WHITELABEL_KEY === WHITELABEL_KEYS.callpro) {
      return <HomepageCallpro {...props} />;
    }

    if (CONFIG_SITE?.WHITELABEL_KEY === WHITELABEL_KEYS.idfood) {
      return <HomepageIDFood {...props} />;
    }

    return <Homepage {...props} />;
  };

  return renderContent();
};

export const getServerSideProps = async ({ locale, query }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common', 'index-page'])),
    pageTemplateCode: 'homepage',
  },
});

export default HomePage;
